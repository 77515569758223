import { render, staticRenderFns } from "./EventMarketingAdvancedSetting.vue?vue&type=template&id=bc5072b0&scoped=true"
import script from "./EventMarketingAdvancedSetting.vue?vue&type=script&lang=js"
export * from "./EventMarketingAdvancedSetting.vue?vue&type=script&lang=js"
import style0 from "./EventMarketingAdvancedSetting.vue?vue&type=style&index=0&id=bc5072b0&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc5072b0",
  null
  
)

export default component.exports