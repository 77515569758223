<template>
  <div class="event-marketing-advanced-setting">
    <p class="card-title">進階條件設定</p>
    <p class="text-[12px] text-[#636363] mb-[20px]">
      您可以依據活動需求，針對符合條件的會員精準行銷
    </p>

    <BaseElForm
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem
        v-if="sourceType === 'level'"
        label="發放對象指定條件(同時符合)"
        prop="includeItems"
      >
        <template slot="label">
          <FormItemTooltipLabel label="發放對象指定條件(同時符合)">
            當您複選指定條件，代表「同時符合」條件者才能收到獎勵。例如：同時符合「完成訂單前30日註冊者」與「銀卡」者才能參與活動。<br>
            因此需密切注意，會員等級不能同時選取，如希望排除特定等級，請使用發放對象排除條件。
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect
          v-model="formData.includeItems"
          :multiple="true"
          no-data-text="請先選擇事件類型"
          :disabled="isEdit"
        >
          <BaseElSelectOption
            v-for="includeItem in filterIncludesConfig"
            :key="includeItem.value"
            :label="includeItem.label"
            :value="includeItem.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem
        v-if="sourceType !== 'level'"
        label="發放對象指定條件(同時符合)"
      >
        <template slot="label">
          <FormItemTooltipLabel label="發放對象指定條件(同時符合)">
            當您複選指定條件，代表「同時符合」條件者才能收到獎勵。例如：同時符合「完成訂單前30日註冊者」與「銀卡」者才能參與活動。<br>
            因此需密切注意，會員等級不能同時選取，如希望排除特定等級，請使用發放對象排除條件。
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect
          v-model="formData.includeItems"
          :multiple="true"
          no-data-text="請先選擇事件類型"
          :disabled="isEdit"
        >
          <BaseElSelectOption
            v-for="includeItem in filterIncludesConfig"
            :key="includeItem.value"
            :label="includeItem.label"
            :value="includeItem.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem
        v-if="showExcludeItem"
        label="發放對象排除條件(同時符合)"
        prop="excludeItems"
      >
        <template slot="label">
          <FormItemTooltipLabel label="發放對象排除條件(同時符合)">
            當您複選指定條件，代表「同時符合」條件者才會被排除。<br>
            例如：同時符合「完成訂單前30日註冊者」與「銀卡」者將無法參與活動。
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect
          v-model="formData.excludeItems"
          :multiple="true"
          no-data-text="請先選擇事件類型"
          :disabled="isEdit"
        >
          <BaseElSelectOption
            v-for="excludeItem in filterExcludesConfig"
            :key="excludeItem.value"
            :label="excludeItem.label"
            :value="excludeItem.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  nextTick,
  watch,
} from 'vue'
import {
  eventAdvancedConfig,
  registerFilters,
  levelFilters,
  birthdayFilters,
  consumptionFilters,
} from '@/config/marketing'
import { find, get, set, toNumber } from 'lodash'
import { noEmptyRules } from '@/validation'
import { useMemberLevel } from '@/use/memberLevel'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'

export default defineComponent({
  name: 'EventMarketingAdvancedSetting',
  components: {
    FormItemTooltipLabel,
  },
  props: ['FormsContext', 'eventData', 'sourceType'],
  setup (props) {
    const { getMemberLevelConfig, memberLevelConfig } = useMemberLevel()
    const formRef = ref(null)
    const formData = reactive({
      includeItems: [],
      excludeItems: [],
    })

    const formRules = {
      includeItems: [noEmptyRules('請選擇指定條件')],
    }

    const filterOptions = {
      register: registerFilters,
      level: levelFilters,
      birthday: birthdayFilters,
      default: consumptionFilters,
    }

    const isEdit = computed(() => {
      return !!get(props.eventData, 'id')
    })

    //  移除掉include的
    const filterConfigBySourceType = computed(() => {
      if (!props.sourceType) {
        return []
      }
      const selectedFilters =
        filterOptions[props.sourceType] || filterOptions.default

      return eventAdvancedConfig.filter(
        (item) => !selectedFilters.includes(item.value),
      )
    })

    const filterIncludesConfig = computed(() => {
      const data = filterConfigBySourceType.value.filter((item) => {
        return !formData.excludeItems.includes(item.value)
      })

      data.forEach(i => {
        if (i.value) {
          if (i.value.includes('level_')) {
            const level = toNumber(get(i.value.split('level_'), '[1]'))
            const levelData = find(memberLevelConfig.value, { level })
            if (levelData) i.label = get(levelData, 'name')
          }
        }
      })

      return data
    })

    const filterExcludesConfig = computed(() => {
      return filterConfigBySourceType.value.filter((item) => {
        return !formData.includeItems.includes(item.value)
      })
    })

    const showExcludeItem = computed(() => {
      const excludeFilters = ['level', 'register']
      return !excludeFilters.includes(props.sourceType)
    })

    const compactData = computed(() => {
      const includeItems = formData.includeItems.map((item) => {
        return {
          type: 'member',
          value: item,
          isInclude: true,
        }
      })
      const excludeItems = formData.excludeItems.map((item) => {
        return {
          type: 'member',
          value: item,
          isInclude: false,
        }
      })
      const data = {
        filters: [...includeItems, ...excludeItems],
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { filters } = data.config
      const includesRes = filters
        .filter((item) => item.isInclude)
        .map(({ value }) => value)
      const excludesRes = filters
        .filter((item) => !item.isInclude)
        .map(({ value }) => value)
      set(formData, 'includeItems', includesRes)
      set(formData, 'excludeItems', excludesRes)
    }

    onMounted(async () => {
      await nextTick()
      getMemberLevelConfig()
      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('advancedSetting', formRef.value)
    })

    watch(formData, () => {
      props.FormsContext.setFormData('advancedSetting', {
        ...compactData.value,
      })
    })

    watch(
      () => props.sourceType,
      () => {
        if (!get(props.eventData, 'id')) {
          formData.includeItems = []
          formData.excludeItems = []
        }
      },
    )

    return {
      formRef,
      formData,
      formRules,
      eventAdvancedConfig,
      filterIncludesConfig,
      filterExcludesConfig,
      showExcludeItem,
      isEdit,
    }
  },
})
</script>

<style lang="postcss" scoped>
.card-title {
  @apply mb-[6px];
}
::v-deep .el-select,
::v-deep .el-input {
  height: fit-content;
}
</style>
